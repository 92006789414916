import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const pageTitle = "向本站提交内容";

const Contribute = () => {

    return (  
    <Layout>
        <h1>{pageTitle}</h1>

        <p>本站现由 <a href="https://scchan.moe">Sicheng Chen</a> 维护，使用 <a href="https://reactjs.org">React.js</a> 和 <a href="https://www.gatsbyjs.com">Gatsby.js</a> 构建。</p>
        <p>如果你想向本站提交内容，请点击首页的“联系”。</p>

        <Link to="/">回主页</Link>
    </Layout>
    );
}

export const Head = () => <Seo title={pageTitle} />

export default Contribute
