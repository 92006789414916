import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const links = [
  {
    text: "公共打印机",
    url: "printer",
    description:
      "打印机的使用方法和注意事项。",
  },
  {
    text: "寝务公开",
    url: "bulletin",
    description:
      "财务信息、公告、活动信息等。",
  },
  {
    text: "如何订水？",
    url: "water",
    description:
      "如何订水？“学友世界公众号”里面订水。",
  },
  {
    text: "揭示板/留言板",
    url: "board",
    description:
      "匿名留言板。",
  },
]


const topPageLinks = [
  {
    text: "浙工大官网",
    url: "https://www.zjut.edu.cn/",
    badge: false,
    description:
      "浙江工业大学官方网站。",
  },
  {
    text: "教务处",
    url: "https://jwc.zjut.edu.cn/",
    badge: false,
    description:
      "浙江工业大学教务处官方网站。",
  },
  {
    text: "图书馆",
    url: "https://lib.zjut.edu.cn/",
    badge: false,
    description:
      "浙江工业大学图书馆官方网站。",
  },
  {
    text: "教务系统",
    url: "http://www.gdjw.zjut.edu.cn/jwglxt/xtgl/login_slogin.html",
    badge: false,
    description:
      "浙江工业大学正方教务系统。",
  },
  {
    text: "计算机学院",
    url: "http://cs.zjut.edu.cn/",
    badge: false,
    description:
      "浙江工业大学计算机学院官方网站。",
  },
  {
    text: "网络教学平台",
    url: "https://zjut.fanya.chaoxing.com/",
    badge: false,
    description:
      "浙江工业大学网络教学平台。",
  }
]

const moreLinks = [
  {
    text: "联系",
    url: "mailto:109@scchan.moe",
  },
  {
    text: "贡献",
    url: "contribute",
  },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className={styles.textCenter}>
      {/*
      <StaticImage
        src="../images/example.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      />
      */}
      <h1>
        欢迎访问 <b>西13-109!</b>
      </h1>
      <p className={styles.intro}>
        <b>实用链接:</b>{" "}
        {topPageLinks.map((link, i) => (
          <React.Fragment key={link.url}>
            <Link to={link.url}>{link.text}</Link>
            {i !== topPageLinks.length - 1 && <> · </>}
          </React.Fragment>
        ))}
        <br />
        部分站点只能在学校内网打开。
      </p>
    </div>
    <ul className={styles.list}>
      {links.map(link => (
        <li key={link.url} className={styles.listItem}>
          <a
            className={styles.listItemLink}
            href={`${link.url}${utmParameters}`}
          >
            {link.text} ↗
          </a>
          <p className={styles.listItemDescription}>{link.description}</p>
        </li>
      ))}
    </ul>
    {moreLinks.map((link, i) => (
      <React.Fragment key={link.url}>
        <a href={`${link.url}${utmParameters}`}>{link.text}</a>
        {i !== moreLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))}
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
