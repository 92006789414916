exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-bulletin-js": () => import("./../../../src/pages/bulletin.js" /* webpackChunkName: "component---src-pages-bulletin-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-printer-js": () => import("./../../../src/pages/printer.js" /* webpackChunkName: "component---src-pages-printer-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-water-js": () => import("./../../../src/pages/water.js" /* webpackChunkName: "component---src-pages-water-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

