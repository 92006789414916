import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const pageTitle = "揭示板/留言板";

function Paper(props) {
    return (
        <div>
            <h2>{props.title}</h2>
            <address>投稿于 {props.date}</address>
            <br></br>
            {props.content}
        </div>
    );
}

class PaperForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {title: '', content: '', password: ''};
  
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
  
    handleSubmit(event) {
        alert('开发中');
        event.preventDefault();
    }
  
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
            <label>
                标题：
                <input type="text" name="title" value={this.state.title} onChange={this.handleChange} />
            </label>
            <br></br>
            <label>
                正文：
                <textarea value={this.state.content} name="content" />
            </label>
            <br></br>
            <label>
                本寝人员验证密码：
                <input type="password" name="password" value={this.state.password} />
            </label>
            <br></br>
            <br></br>
            <input type="submit" value="提交"/>
            </form>
        );
    }
}

const Board = () => {
    const papers = [
        {
            title: "开发中",
            date: "2022年10月17日",
            content: (
                <div>
                    <p>暂未启用！</p>
                </div>
            )
        },
    ];

    return (  
    <Layout>
        <h1>{pageTitle}</h1>

        <PaperForm />

        {papers.map((paper) => (
            <Paper title={paper.title} date={paper.date} content={paper.content}/>
        ))}

        <Link to="/">回主页</Link>
    </Layout>
    );
}

export const Head = () => <Seo title={pageTitle} />

export default Board
