import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const pageTitle = "公共打印机";

const Printer = () => {

    return (  
    <Layout>
        <h1>{pageTitle}</h1>

        <p>打印机服务现在得到完全的更新。如果您现在正在使用俺的无限局域网连接互联网，您可以透过 AirPrint 方便地打印，无需配置任何内容。如果您不在俺的局域网内，可以透过 zerotier 加入专为 Xi13-109 公共打印机而建立的虚拟局域网 PrintExchange 网路来打印，无需过多配置。</p>
        
        <p>如果您已在俺的局域网内，或者已经加入 PrintExchange 虚拟局域网，请跳过下面全部内容。如果您不知道如何使用 AirPrint，在 Apple 设备上，通常只需要点击打印而不需要其他配置即可使用。在 Windows 10 或 11 版本的 PC 上，亦应可以在设置中自动发现打印机，点击添加即可。</p>

        <h2>第一步：加入 PrintExchange 网</h2>

        <p>首先安装 zerotier 软件。对于 Windows 用户，您可以 <a href="https://download.zerotier.com/dist/ZeroTier%20One.msi">点击此处下载</a>。它还支持 macOS、Linux、Android、iOS等其他作业系统，请去它的官方网站查看更多信息。下文以 Windows 为例。</p>

        <p>打开 zerotier 软件。</p>

        <p>在状态栏找到 zerotier 的图标，是橙色的图标。右击图标选择“Join Network”。</p>

        <p>键入网路号：联系我取得网路号。</p>

        <p>联系我批准您加入。</p>

        <h2>第二步：打印机的位置</h2>

        <p>如果您的机器没有自动查找到打印机，请手动配置。打印机的位置：10.147.19.1（PrintExchange 网）或10.10.10.142（俺的局域网）。</p>

        <h2>特别说明</h2>
        <p>提醒：透过 PrintExchange 网，您可以在世界上任何地方访问到打印机。</p>
        <p><em>警告：严禁使用打印机打印违法信息。打印留存记录，本人将配合一切可能的调查，违者后果自负。</em></p>
        
        <Link to="/">回主页</Link>
    </Layout>
    );
}

export const Head = () => <Seo title={pageTitle} />

export default Printer
