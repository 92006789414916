import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const pageTitle = "如何订水？";

const Water = () => {

    return (  
    <Layout>
        <h1>{pageTitle}</h1>

        <p>如何订水？“学友世界公众号”里面订水。</p>

        <Link to="/">回主页</Link>
    </Layout>
    );
}

export const Head = () => <Seo title={pageTitle} />

export default Water
