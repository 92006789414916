import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const pageTitle = "寝务公开";

function Paper(props) {
    return (
        <div>
            <h2>{props.title}</h2>
            <address>{props.author} 发布于 {props.date}</address>
            <br></br>
            {props.content}
        </div>
    );
}

const Bulletin = () => {

    const papers = [
        {
            title: "关于不要在室内烹饪的通知",
            date: "2022年11月18日",
            author: "匿名",
            content: (
                <div>
                    <p>在室内炒菜，影响通风，不利于健康。</p>
                    <p>在厕所炒菜，卫生得不到保障，影响健康。</p>
                    <p>请大家不要在室内烹饪。</p>
                </div>
            )
        },
        {
            title: "关于不要把垃圾留在厕所的提醒",
            date: "2022年10月17日",
            author: "Sicheng Chen",
            content: (
                <div>
                    <p>请及时将废弃包装袋等垃圾移出厕所，不要堆积。</p>
                    <p>将瓶罐类物品放在洗手台等区域也是不被建议的。</p>
                    <p>谢谢配合。</p>
                </div>
            ),
        },
        {
            title: "关于饮水机退费（朝晖）的告示",
            date: "2022年10月17日",
            author: "Sicheng Chen",
            content: (
                <div>
                    <p>请前往朝晖校区的同志顺便把饮水机押金退费！收据在实体揭示板上。</p>
                </div>
            )
        },
        {
            title: "寝室网站开放通知",
            date: "2022年10月17日",
            author: "Sicheng Chen",
            content: (
                <div>
                    <p>109 原设有打印机配置指南网站，现在利用 React.js 和 Gatsby.js，重新架设了寝室网站，整合了寝室各项电子服务和资源，对于推进寝室信息化建设具有重大意义。</p>
                    <h3>主要功能</h3>
                    <ul>
                        <li>公共打印机指南</li>
                        <li>寝务公开</li>
                        <li>如何订水？指南</li>
                        <li>揭示板/留言板</li>
                        <li>实用链接</li>
                    </ul>
                    <h3>开发进度</h3>
                    <p>目前网站已经完成了基本功能的开发，揭示板/留言板功能暂缓上线。</p>
                </div>
            ),
        },
    ]

    return (  
    <Layout>
        <h1>{pageTitle}</h1>

        {papers.map((paper) => (
            <Paper title={paper.title} date={paper.date} content={paper.content} author={paper.author}/>
        ))}

        <Link to="/">回主页</Link>
    </Layout>
    );
}

export const Head = () => <Seo title={pageTitle} />

export default Bulletin
